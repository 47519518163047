textarea {
  resize: vertical;
  width: 100%;
  height: 100px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
  padding: 10px;
  font-family: Arial, Helvetica, sans-serif;
  word-spacing: 3px;
}

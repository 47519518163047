#headerNav {
  display: flex;
  justify-content: space-evenly;
  /* padding: 0px 50px 0px; */
}
#NavLink {
  margin-right: 5px;
  text-decoration: none;
  color: #6d7175;
  font-size: 14px;
  /* font-size: 8px; */
}
@media only screen and (max-width: 600px) {
  #headerNav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0px 2px 0px;
  }
  #NavLink {
    margin-right: 5px;
    text-decoration: none;
    color: #6d7175;
    font-size: 8px;
    /* font-size: 8px; */
  }
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
 
}

/* .btn {
  border: 1px solid gray;
  color: gray;
  background-color: white;
  padding: 7px 15px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
} */

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
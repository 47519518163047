.paginationButtons{
    /* width: 80%; */
    height: 40px;
    list-style: none;
    display:flex;
    justify-content: center;
   
}
.paginationButtons a {
  padding: 2px 9px;
  margin: 8px;
  border-radius: 5px;
  /* border: 1px solid #EA5569; */
  color: #EA5569;
  cursor: pointer;
}

.paginationButtons a:hover {
  color: white;
  background-color: #EA5569;
}

.paginationActive a {
  color: white;
  background-color: #EA5569;
}

.paginationDisabled a {
  color: grey;
  background-color: grey;
}
.input-field {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid lightgray;
  outline: none;
  padding: 10px;
}
.message {
  margin-top: 10px;
  font-size: 14px;
}

.success {
  color: rgb(37, 133, 64);
}

.error {
  color: rgba(255, 77, 109);
}

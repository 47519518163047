.styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 0 25px 0;
    font-size: 0.9em;
    cursor: pointer;
    /* font-family: sans-serif; */
    /* min-width: 400px; */
    /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}
.styled-table thead  {
    font-size: 16px;
}
.styled-table thead th {
   padding: 8px 10px;
   
}
.styled-table thead tr {
    background-color: #EA5569;
    color: #ffffff;
    text-align: left;
}
.styled-table th,
.styled-table td {
    padding: 12px 15px;
}


.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #f2faf7;
}
.styled-table tr {
   border-bottom: 1px solid lightgray;
}
.styled-table tbody :hover{
 background: lightgray;
 
}

@media(max-width: 500px){
	.styled-table thead{
		display: none;
	}

	.styled-table, .styled-table tbody, .styled-table tr, .styled-table td{
		display: block;
		width: 100%;
	}
	.styled-table tr{
		margin-bottom:15px;
	}
	.styled-table td{
		text-align: right;
		padding-left: 50%;
		text-align: right;
		position: relative;
	}
	.styled-table td::before{
		content: attr(data-label);
		position: absolute;
		left:0;
		width: 50%;
		padding-left:15px;
		font-size:15px;
		font-weight: bold;
		text-align: left;
	}
}








